import store from '../../store'
import router from '../../router'

export default function errorResponseHandler(error) {
    if (error.status === 401 && !error.config.url.includes('https://app.rte.im/admin/v1/')) {
        store.dispatch('logOut')
        router.push({ path: '/auth' })
    }
    //
    // if (error.status === 404) {
    //     error.data.message = "The page was not found"
    // }
    //
    // if (error.status === 422) {
    //     error.data.message = "Invalid data was entered"
    // }
    //
    // if (error.status === 502) {
    //     error.data.message = "Bad gate away"
    // }
    //
    // if (error.status === 500) {
    //     error.data.message = "Server response time expired"
    // }

    return error
}