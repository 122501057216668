<template>
  <b-modal id="storage-modal"
           v-model="show"
           size="sm"
           hide-footer
           hide-header
           @hide="$store.dispatch('toggleStorage')">
    <div>
      <label class="storage__add" ref="storageAdd">
        <input class="d-none" type="file" @change="addFile">
        <i class="la la-cloud-upload"></i>
        <span>Нажмите, чтобы загрузить файл</span>
      </label>
    </div>
  </b-modal>
</template>

<script>
import {placeImage} from "@/helpers/profile/profile.js";

export default {
  name: "ImageUploader",
  data() {
    return {
      show: true,
      file: null
    }
  },
  computed: {
    url() {
      return this.$store.getters.url
    },
    user() {
      return this.$store.getters.user
    },
    storageTarget() {
      return this.$store.getters.storageTarget
    }
  },
  mounted() {
    setTimeout(() => {
      this.$refs['storageAdd'].click()
    }, 0)
  },
  methods: {
    addFile(e) {
      let meta = new FormData()
      let files = e.target.files || e.dataTransfer.files
      if (files.length) {
        meta.append('file', files[0])
        if (files[0].size> 2097152) {
          this.$swal.fire({
            title: this.$t('general.error'),
            text: this.$t('general.errorImageSize') ,
            type: 'error'
          })
        } else {
          placeImage(meta).then((url) => {
          this.$store.dispatch('selectFile', {
            path: url,
            file_size: files[0].size
          })
          }).catch(error => {
            this.$swal(this.$t('general.error'), error.data.message, "error")
          })
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.storage__add
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  margin: -1.25rem
  min-height: 250px
  background: #f9f9f9
  cursor: pointer
  &:hover
    i,span
      opacity: .5
  i
    font-size: 60px
    color: #777
  span
    color: #777
    font-size: 16px
    text-align: center
</style>