<template>
  <div>
    <div class="kt-subheader kt-grid__item" id="kt_subheader">
      <div class="kt-container  kt-container--fluid" :class="{'pr-menu': eventId}">
        <BreadCrumbs :breadCrumbs="breadCrumbs"/>

        <div class="kt-left-grow">
          <div class="help-video ml-auto" v-if="secondVideo &&  lang !== 'uk_ua'">
            <a :href="secondVideo.url" target="_blank" @click.prevent="modalVideo = secondVideo">
              <svg height="35"
                   style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"
                   version="1.1" viewBox="0 0 24 24" xml:space="preserve"
                   xmlns="http://www.w3.org/2000/svg">
                <rect height="24" id="Artboard15" style="fill:none;" width="24" x="0" y="0"/>
                <g>
                  <path d="M2.093,9.075c0.125,-1.941 1.629,-3.509 3.562,-3.716c2.005,-0.202 4.136,-0.311 6.345,-0.311c2.209,0 4.34,0.109 6.345,0.312c1.933,0.206 3.437,1.774 3.562,3.715c0.061,0.956 0.093,1.933 0.093,2.925c0,0.992 -0.032,1.969 -0.093,2.925c-0.125,1.941 -1.629,3.509 -3.562,3.716c-2.005,0.202 -4.136,0.311 -6.345,0.311c-2.209,0 -4.34,-0.109 -6.345,-0.312c-1.933,-0.206 -3.437,-1.774 -3.562,-3.715c-0.061,-0.956 -0.093,-1.933 -0.093,-2.925c0,-0.992 0.032,-1.969 0.093,-2.925Z" style="fill:#f00;"/>
                  <path d="M15.055,12l-4.909,2.995l0,-5.99l4.909,2.995Z" style="fill:#fff;"/>
                </g>
              </svg>
              <span>{{$t(`general.video.${secondVideo.title}`)}}</span>
            </a>
          </div>
          <div class="help-video" :class="{'ml-auto': !secondVideo || (secondVideo.title === 'tildaHelp' && lang === 'uk_ua')}" v-if="video">
            <a :href="video.url" target="_blank" @click.prevent="handleOpenVideo(video)">
              <svg height="35"
                   style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"
                   version="1.1" viewBox="0 0 24 24" xml:space="preserve"
                   xmlns="http://www.w3.org/2000/svg">
                <rect height="24" id="Artboard15" style="fill:none;" width="24" x="0" y="0"/>
                <g>
                  <path d="M2.093,9.075c0.125,-1.941 1.629,-3.509 3.562,-3.716c2.005,-0.202 4.136,-0.311 6.345,-0.311c2.209,0 4.34,0.109 6.345,0.312c1.933,0.206 3.437,1.774 3.562,3.715c0.061,0.956 0.093,1.933 0.093,2.925c0,0.992 -0.032,1.969 -0.093,2.925c-0.125,1.941 -1.629,3.509 -3.562,3.716c-2.005,0.202 -4.136,0.311 -6.345,0.311c-2.209,0 -4.34,-0.109 -6.345,-0.312c-1.933,-0.206 -3.437,-1.774 -3.562,-3.715c-0.061,-0.956 -0.093,-1.933 -0.093,-2.925c0,-0.992 0.032,-1.969 0.093,-2.925Z" style="fill:#f00;"/>
                  <path d="M15.055,12l-4.909,2.995l0,-5.99l4.909,2.995Z" style="fill:#fff;"/>
                </g>
              </svg>
              <span>{{$t(`general.video.${video.title}`)}}</span>
            </a>
            <div class="help-video__moving" v-if="videoPreviewStatus !== 0"
                 :class="{'show': videoPreviewStatus > 1, 'animate': videoPreviewStatus === 3}">
              <div class="h-100" @click.prevent="handleOpenVideo(video)">
                <iframe width="560"
                        height="315"
                        :src="'https://www.youtube.com/embed/' + getVideoCode(video)"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen>
                </iframe>
              </div>
              <button class="btn btn-icon btn-transparent" @click="videoPreviewStatus=0">
                <i class="la la-close"></i>
              </button>
            </div>
          </div>
          <div v-if="eventId" class="event-navigation" :class="{'ml-auto': !video, 'ml-2': video}">
            <a data-toggle="dropdown" class="btn btn-sm btn-clean btn-icon btn-icon-md cursor-pointer" v-on:click="activateMenu($event)">
              <i class="la la-reorder"/>
            </a>
            <EventNavigationMenu
                v-bind:event="event"
                v-bind:activatedMenuId="activatedMenuId"
                v-bind:eventId="activatedMenuId"
                v-on:setActivatedMenuId="activateMenuId"
                v-bind:coordX="coordX"
                v-bind:coordY="coordY"
                v-bind:place="'subheader'"
            />
          </div>
        </div>

      </div>
    </div>
    <div class="help-modal-overlay" v-if="modalVideo" @click="modalVideo = false"></div>
    <div class="help-modal" v-if="modalVideo">
<!--      <header class="help-modal-header">-->
<!--        <h4 class="mb-0">Инструкция</h4>-->
<!--        <span class="cursor-pointer" @click="showModal = false">-->
<!--          <i class="flaticon2-cross p-0"></i>-->
<!--        </span>-->
<!--      </header>-->
      <iframe width="560"
              height="315"
              :src="'https://www.youtube.com/embed/' + getVideoCode()"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen>
      </iframe>
    </div>
  </div>
</template>

<script>
import EventNavigationMenu from "@/components/events/general/EventNavigationMenu";
import BreadCrumbs from "@/components/subHeader/BreadCrumbs";
import youtube from "@/assets/help-youtube.svg"

export default {
  name: 'SubHeader',
  props: {
    breadCrumbs: {
      type: Array
    },
    event: {
      required: false
    },
    eventId: {
      required: false
    },
    video: {
      required: false
    },
    secondVideo: {
      required: false
    }
  },
  components: {
    EventNavigationMenu,
    BreadCrumbs
  },
  computed: {
    lang() {
      return this.$store.state.curLanguage.locale
    }
  },
  data: function () {
    return {
      youtube,
      modalVideo: false,
      videoPreviewStatus: 0,
      shownVideos: [],
      activatedMenuId: '',
      coordX: null,
      coordY: null,
    }
  },
  mounted() {
    if (this.video) {
      if (localStorage.getItem('rteShownVideos')) {
        try {
          this.shownVideos = JSON.parse(localStorage.getItem('rteShownVideos'))
        } catch(e) {
          this.shownVideos = []
        }
      }
      if (!this.shownVideos.includes(this.getVideoCode(this.video))) {
        setTimeout(() => {
          this.videoPreviewStatus = 2
          setTimeout(() => {
            this.videoPreviewStatus = 3
          }, 1000)
          setTimeout(() => {
            this.videoPreviewStatus = 1
          }, 4000)
          setTimeout(() => {
            this.videoPreviewStatus = 0
          }, 5000)
        }, 0)
      }
    }
  },
  methods: {
    handleOpenVideo(vd) {
      this.modalVideo = vd
      this.videoPreviewStatus = 0
    },
    getVideoCode(vd) {
      let field = 'url'
      if (this.video[this.lang]) {
        field = this.lang
      }
      let target = vd ? vd : this.modalVideo
      if (target[field].includes('http')) {
        if (target[field].includes('.be')) {
          return target[field].split('.be/')[1]
        } else {
          return target[field].split('v=')[1]
        }
      } else return target[field]
    },
    activateMenu(evt) {
      if (this.activatedMenuId == this.eventId) this.activatedMenuId = null
      else this.activatedMenuId = this.eventId
      this.coordX = evt.target.getBoundingClientRect().left
      this.coordY = evt.target.offsetTop
    },
    activateMenuId(eventId) {
      this.activatedMenuId = eventId
    },
  },
  watch: {
    videoPreviewStatus(value) {
      if (value === 0) {
        this.shownVideos.push(this.getVideoCode(this.video))
        try {
          localStorage.setItem('rteShownVideos', JSON.stringify(this.shownVideos))
          //eslint-disable-next-line
        } catch (e) {}
      }
    }
  }
}
</script>

<style lang="sass">
.kt-subheader
  z-index: 105
  .kt-container
    justify-content: flex-start
.kt-left-grow
  display: flex
  align-items: center
  flex-grow: 1

.help-video
  display: flex
  align-items: center
  a
    color: #646c9a
    font-weight: bold
    border-radius: 5px
    padding: 5px
    background: transparent
    transition: all .1s
    &:hover
      background: rgba(77,89,149,.06)
  svg
    margin-right: 5px
.help-video__moving
  width: 300px
  height: 180px
  position: absolute
  top: calc(100% + 15px)
  right: -200%
  overflow: hidden
  border-radius: 10px
  box-shadow: 0 0 15px rgba(0,0,0,.5)
  cursor: pointer
  transition: right 1s, box-shadow 0.3s
  &.show
    right: 0
  &.animate
    animation: shake 1.5s cubic-bezier(.36,.07,.19,.97) both infinite
    transform: translate3d(0, 0, 0)
    backface-visibility: hidden
    perspective: 1000px
  &:hover
    box-shadow: 0 0 35px rgba(0,0,0,.7)
  iframe
    width: calc(100% + 100px)
    height: calc(100% + 100px)
    position: absolute
    inset: -50px
    pointer-events: none
  &.show .btn.btn-icon
    position: absolute
    top: 5px
    right: 5px
    width: 3rem !important
    height: 3rem !important
    backdrop-filter: blur(2px)
    i
      font-size: 20px !important
@keyframes shake
  10%, 90%
    transform: translate3d(-1px, 0, 0)
  20%, 80%
    transform: translate3d(2px, 0, 0)
  30%, 50%, 70%
    transform: translate3d(-4px, 0, 0)
  40%, 60%
    transform: translate3d(4px, 0, 0)
.kt-header-full-width
  .kt-subheader
    left: 0 !important
.event-navigation
  display: flex
  align-items: center
  .btn
    margin: 0
    margin-left: 0 !important
  .la-reorder
    font-size: 1.4rem !important
    position: relative
    height: 2.5rem
    width: 2.5rem
    &:before
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)

.help-modal-overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 100
  background: rgba(0,0,0,.5)
.help-modal
  background: #fff
  width: 70%
  height: 80%
  position: fixed
  top: 50%
  left: 50%
  transform: translate(-50%,-50%)
  box-shadow: 0 0 10px rgba(0,0,0,.1)
  z-index: 105
  border-radius: 20px
  iframe
    width: 100%
    height: 100%
    overflow: hidden
    border-radius: 20px
.help-modal-header
  padding: 15px
  display: flex
  align-items: center
  justify-content: space-between
@media screen and (max-width: 991px)
  .help-video__moving
    display: none
</style>