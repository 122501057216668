import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en_us from './langs/en.json'
import ru_ru from './langs/ru.json'
import uk_ua from './langs/uk_ua.json'
import es_ES from './langs/es_ES.json'


Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: 'ru',
    messages: {
        en_us, ru_ru, uk_ua, es_ES
    }
});

