<template>
  <div v-click-outside="onClickOutside" v-if="user.isLogin">
    <MobileHeader></MobileHeader>
    <div id="kt_header" class="kt-header kt-grid__item kt-header--fixed" >

        <div class="kt-header-menu-wrapper" id="kt_header_menu_wrapper">
        <!-- this is place for left top bar menu-->
          <router-link to="/" v-if="user.role.role === 'registrar'">
            <img class="mh-100 p-3" :src="LogoBlack" alt="RegToEvent">
          </router-link>
        </div>
        <div class="kt-header__topbar d-flex align-items-center w-100" style="padding-left: 15px;">
          <button class="btn btn-label btn-label-brand btn-bold mr-3"
                  v-if="user.role.role === 'admin'"
                  @click="$store.dispatch('toggleStorage')">
            <i class="flaticon-upload"></i>
            {{$t('storage.title')}}
          </button>
          <div class="kt-header__topbar-item" :class="userRole === 'admin' ? 'mr-auto' : 'mr-2'">
            <a href="https://t.me/RTE_LMSka" target="_blank" class="btn btn-label btn-label-brand btn-bold shake" ref="shakeButton">
              <i class="flaticon2-telegram-logo"></i>
              Support
            </a>
          </div>
          <div class="kt-header__topbar-item mr-auto" v-if="userRole !== 'admin'">
            <a href="https://help.regtoevent.com" target="_blank" class="btn btn-label btn-label-brand btn-bold">
              <i class="flaticon2-telegram-logo"></i>
              FAQ
            </a>
          </div>
          <div class="kt-header__topbar-item" v-if="user.new_bills">
            <router-link to="/profile/payments" class="balance-link">
              <i class="la la-dollar mr-1" style="font-size:16px"></i>
              <span class="payment--unread pulse">{{user.new_bills}}</span>
            </router-link>
          </div>
          <div class="kt-header__topbar-item d-flex align-items-center mr-2" v-if="!loading && userData && userRole !== 'registrar' && userRole !== 'ticket_partner' && user.role.id != 9">
            <AdminBalance v-if="user.role.id === 1" />
            <router-link class="balance-link" style="color:#6c7293" to="/balance" v-else>
              {{$t('balance.yourBalance')}}: <b class="pl-1">{{parseFloat(userData.credit_balance)}} EURO</b>
            </router-link>
          </div>
          <LangBar/>
          <UserBar :userData="userData" :loading="loading"></UserBar>
        </div>
    </div>

    <b-modal v-model="modalShow" size="lg" no-close-on-backdrop no-close-on-esc>
      <template #modal-header="{ close }">
        <h5 class="m-0 text-dark">{{$t('purposeModal.title')}}</h5>
      </template>
      <div class="text-dark">
        <p>{{$t('purposeModal.fewVariants')}}</p>
        <label class="kt-option kt-option--plain mb-3 mt-3" v-for="purpose in purposes" :key="purpose.id">
          <span class="kt-checkbox kt-checkbox--single mb-0">
            <input type="checkbox" name="purpose" @change="selectPurpose(purpose)">
            <span></span>
          </span>
          <span class="kt-option__label">{{purpose.text}}</span>
        </label>
        <div class="form-group mt-5 mb-2">
          <label>{{$t('purposeModal.eventName')}}</label>
          <input type="text" class="form-control" :placeholder="$t('purposeModal.eventNamePlaceholder')" v-model="firstEventName">
        </div>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <button class="btn btn-clean btn-sm mr-auto" @click="handleSendPurpose(true)">
          <template v-if="sendPurposeLoading !== 1">
            {{$t('purposeModal.cancelText')}}
          </template>
          <b-spinner v-else small></b-spinner>
        </button>
        <b-button size="sm" variant="success" @click="handleSendPurpose(false)">
          <template v-if="sendPurposeLoading !== 2">
            {{$t('general.actions.send')}}
          </template>
          <b-spinner v-else small></b-spinner>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'

import UserBar from '@/components/topNavBar/UserBar.vue'
import LangBar from '@/components/topNavBar/LangBar.vue'
import AdminBalance from "@/components/topNavBar/AdminBalance.vue";
import MobileHeader from '@/components/topNavBar/MobileHeader.vue'
import {showProfile} from "@/helpers/profile/profile";
import {postAnswer} from '@/helpers/whyrte/whyRte'

import LogoBlack from '@/assets/logo-black.png'

export default {
  name: 'TopNavBar',
  components: {
    UserBar,
    LangBar,
    AdminBalance,
    MobileHeader
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data () {
    return {
      LogoBlack,
      loading: false,
      sendPurposeLoading: false,
      userData: null,
      modalShow: false,
      purposes: [
        {id: 1, text: this.$t('purposeModal.purpose_1')},
        {id: 2, text: this.$t('purposeModal.purpose_2')},
        {id: 3, text: this.$t('purposeModal.purpose_3')},
        {id: 4, text: this.$t('purposeModal.purpose_4')},
        {id: 5, text: this.$t('purposeModal.purpose_5')},
        {id: 6, text: this.$t('purposeModal.purpose_6')},
        {id: 7, text: this.$t('purposeModal.purpose_7')},
        {id: 8, text: this.$t('purposeModal.purpose_8')},
        {id: 9, text: this.$t('purposeModal.purpose_9')},
        {id: 10, text: this.$t('purposeModal.purpose_10')},
        {id: 11, text: this.$t('purposeModal.purpose_11')}
      ],
      selectedPurposes: [],
      firstEventName: ''
    }
  },
  mounted() {
    let clearTime = parseInt(localStorage.getItem('clearTimeRTE'))
    if (this.$store.getters.user.isLogin && new Date().getTime() > clearTime) {
      this.$store.dispatch('logOut')
      this.$router.push({path: '/auth'})
    }
    this.$bus.$on('fetchUserData', this.fetchUserData)
    if (this.$store.getters.user.isLogin) {
      this.loading = true
      this.$emit('loading', true)
      this.fetchUserData()
    } else {
      this.userData = this.user
      this.loading = false
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    userId() {
      return localStorage.getItem('userId')
    },
    userRole() {
      return localStorage.getItem('userRoleRTE')
    },
    token() {
      return localStorage.getItem('authorizationRTEAccessToken').split(' ')[1]
    }
  },
  methods: {
    selectPurpose(item) {
      let isInclude = this.selectedPurposes.find(el => el.id === item.id)
      if (isInclude) {
        this.selectedPurposes.splice(this.selectedPurposes.indexOf(item), 1)
      } else this.selectedPurposes.push(item)
    },
    handleSendPurpose(isEmpty = false) {
      if (this.sendPurposeLoading) return
      let meta = {
        purposes: this.selectedPurposes,
        event_name: this.firstEventName
      }
      if (isEmpty) {
        this.sendPurposeLoading = 1
        meta.purposes = [{id: 0, text: 'Не хочу отвечать на Ваши позорные вопросы!'}]
      } else {
        this.sendPurposeLoading = 2
        if (!this.selectedPurposes.length) {
          meta.purposes = [{id: 0, text: 'Орг не выбрал ни единого пункта.'}]
        }
      }
      postAnswer(meta)
      .catch(error => {
        this.$swal.fire({
          title: error.data.message,
          type: 'error',
          toast: true,
          showConfirmButton: false,
          timer: 2000,
          position: 'bottom'
        })
      })
      .finally(() => {
        this.modalShow = false
        this.sendPurposeLoading = false
      })
    },
    onClickOutside() {
      this.$store.dispatch('hideMobileHeader');
    },
    fetchUserData() {
      showProfile().then(response => {
        this.userData = response.data
        this.$store.dispatch('setUserData', response.data)
      }).finally(() => {
        this.loading = false
        this.$emit('loading', false)

        if (this.userData && this.userData.why_rte) {
          setTimeout(() => {
            this.modalShow = true
          }, 2000)
        }
  
        if (this.user.isLogin && this.$refs.shakeButton) {
          setTimeout(() => {
            this.$refs.shakeButton.classList.remove('shake')
          },15000)
        }
      }).catch(error => {
        this.$swal(this.$t('general.error'), error.data.message, 'error')
      })
    }
  }
}
</script>

<style lang="sass">
@keyframes pulse
  0%
    transform: scale(0.85)
  100%
    transform: scale(1.15)
.payment--unread
  display: flex
  align-items: center
  justify-content: center
  background: #fd397a
  color: #fff
  border-radius: 50%
  width: 20px
  height: 20px
  font-size: 12px
  font-weight: 500
  animation: pulse .3s linear alternate infinite

.shake
  animation: shake 2s cubic-bezier(.36,.07,.19,.97) both infinite
  transform: translate3d(0, 0, 0)
  backface-visibility: hidden
  perspective: 1000px
@keyframes shake
  10%, 90%
    transform: translate3d(-1px, 0, 0)
  20%, 80%
    transform: translate3d(2px, 0, 0)
  30%, 50%, 70%
    transform: translate3d(-4px, 0, 0)
  40%, 60%
    transform: translate3d(4px, 0, 0)

.kt-header-full-width
  .kt-header
    left: 0 !important
.balance-link
  height: 44px
  padding: 0 8px
  display: flex
  align-items: center
  border-radius: 5px
  position: relative
  &:hover
    background-color: rgba(77,89,149,.06)
.balance-info
  height: 44px
  padding: 0 8px
  display: flex
  align-items: center
.mobile-logo
  max-width: 80px
  img
    max-width: 100%

@media screen and (max-width: 500px)
  .balance-info
    flex-direction: column
    align-items: flex-end
    padding-right: 0
    margin-right: -0.5rem
</style>