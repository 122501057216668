<template>
  <div class="kt-subheader__main">
    <div class="kt-subheader__main">
      <span class="kt-subheader__separator kt-hidden"/>

      <div class="kt-subheader__breadcrumbs">
        <router-link to="/" class="kt-subheader__breadcrumbs-home"><i class="flaticon2-shelter"></i></router-link>
          <div v-for="(crumb, index) in activeBreadCrumbs" :key="index" class="flex-align-center">
            <router-link
                :to="crumb.link"
                class="kt-subheader__breadcrumbs-link text-truncate">
                <template v-if="crumb.translate">
                    {{$t(crumb.name)}}
                </template>
                <template v-else>
                    {{crumb.name}}
                </template>
            </router-link>
            <span class="kt-subheader__breadcrumbs-separator" ></span>
          </div>
        <span class="kt-subheader__breadcrumbs-link kt-subheader__breadcrumbs-link--active">{{$t(namePage)}}</span>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'BreadCrumbs',

  props: {
    breadCrumbs: {
      type: Array
    },
  },

  computed: {
    namePage() {
      return this.breadCrumbs[this.breadCrumbs.length - 1].name
    },

    activeBreadCrumbs() {
      return this.breadCrumbs.length > 1 ? this.breadCrumbs.slice(0, -1) : []
    }
  },

}
</script>

<style lang="sass">
  .kt-subheader__breadcrumbs-link
    max-width: 200px
    white-space: nowrap
  .flex-align-center
      display: flex
      align-items: center
</style>