import axios from 'axios'
import store from '@/store';

let qs = require('qs');

export function getAllTicketSchemas(eventId) {
    return new Promise((res, rej) => {
        axios.get(store.state.url + '/api/v1/events/' + eventId + '/orders/tickets/schemas', store.state.credentials)
            .then(function (response) {
                res({
                    data: response.data
                })
            })
            .catch(function (error) {
                rej(error.response);
            });
    });
}

export function getAllTicketSchemasForEvents(params) {
  let requestData = {}
  requestData.headers = store.state.credentials.headers

  if(params !== undefined) {
    requestData.params = params
  }
  return new Promise((res, rej) => {
    axios.get(
      store.state.url + '/api/v1/events/orders/tickets/schemas/multiple',
      {
        headers: requestData.headers,
        params: requestData.params,
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      })
      .then(function (response) {
        res({
          data: response.data
        })
      })
      .catch(function (error) {
        rej(error.response);
      });
  });
}

export function createTicketSchema(meta, eventId) {
    return new Promise((res, rej) => {
        axios.post(store.state.url + '/api/v1/events/' + eventId + '/orders/tickets/schemas', meta, store.state.credentials)
            .then(function (response) {
                res(response.data);
            })
            .catch(function (error) {
                rej(error.response);
            });
    });
}

export function updateTicketSchema(eventId, schemaId, meta) {
    return new Promise((res, rej) => {
        axios.put(store.state.url + '/api/v1/events/' + eventId + '/orders/tickets/schemas/' + schemaId, meta, store.state.credentials)
            .then(function (response) {
                res(response.data);
            })
            .catch(function (error) {
                rej(error.response);
            });
    });
}

export function deleteTicketSchema(eventId, schemaId) {
  return new Promise((res, rej) => {
    axios.delete(store.state.url + '/api/v1/events/' + eventId + '/orders/tickets/schemas/' + schemaId, store.state.credentials)
      .then(function (response) {
        let data = response.data;
        res({
          data: data
        })
      })
      .catch(function (error) {
        rej(error.response.data)
      });
  });
}


export function getAllTicketTypes() {
    return new Promise((res, rej) => {
        axios.get(store.state.url + '/api/v1/events/orders/tickets/types', store.state.credentials)
            .then(function (response) {
                res({
                    data: response.data
                })
            })
            .catch(function (error) {
                rej(error.response);
            });
    });
}

export function getAllModerationTypes() {
    return new Promise((res, rej) => {
        axios.get(store.state.url + '/api/v1/events/orders/tickets/moderation-types', store.state.credentials)
            .then(function (response) {
                res({
                    data: response.data
                })
            })
            .catch(function (error) {
                rej(error.response);
            });
    });
}

export function getUpgradedTickets (eventId) {
  return new Promise((res, rej) => {
    axios.get(store.state.url + '/api/v1/events/' + eventId + '/orders/tickets/schemas/upgrade', store.state.credentials)
      .then(function (response) {
        res({
          data: response.data
        })
      })
      .catch(function (error) {
        rej(error.response);
      });
  });
}

export function createUpgradeTicket(eventId, meta) {
  return new Promise((res, rej) => {
    axios.post(store.state.url + '/api/v1/events/' + eventId + '/orders/tickets/schemas/upgrade', meta, store.state.credentials)
      .then(function (response) {
        res({
          data: response.data
        })
      })
      .catch(function (error) {
        rej(error.response);
      });
  });
}

export function deleteUpgradeTicket(eventId, upgradeId) {
  return new Promise((res, rej) => {
    axios.delete(store.state.url + '/api/v1/events/' + eventId + '/orders/tickets/schemas/upgrade/' + upgradeId, store.state.credentials)
      .then(function (response) {
        res({
          data: response.data
        })
      })
      .catch(function (error) {
        rej(error.response);
      });
  });
}

export function updateUpgradeTicket(eventId, upgradeId, meta) {
  return new Promise((res, rej) => {
    axios.put(store.state.url + '/api/v1/events/' + eventId + '/orders/tickets/schemas/upgrade/' + upgradeId, meta, store.state.credentials)
      .then(function (response) {
        res({
          data: response.data
        })
      })
      .catch(function (error) {
        rej(error.response);
      });
  });
}


