<template>
  <div class="kt-grid kt-grid--hor kt-grid--root empty-layout">
      <slot />
  </div>
</template>

<script>
export default {
  name: 'app',
  beforeMount() {
    this.$i18n.locale = this.$store.state.curLanguage.locale
  }
}
</script>

<style lang="scss">

</style>