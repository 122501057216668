import axios from "axios";
import store from "../../store";
import errorResponseHandler from "../responseError/errorResponseHandler";

let qs = require("qs");

const CancelToken = axios.CancelToken;
let source

export function cancelRequest() {
  if (source) source.cancel('canceled')
}

export function createEvent(meta) {
  return new Promise((res, rej) => {
    axios.post(store.state.url + "/api/v1/events", meta, store.state.credentials)
    .then(function(response) {
      res(response.data);
    })
    .catch(function(error) {
      rej(errorResponseHandler(error.response));
    });
  });
}

export function copyEvent(eventId, meta) {
  return new Promise((res, rej) => {
    axios.post(store.state.url + "/api/v1/events/" + eventId + "/copy", meta, store.state.credentials)
    .then(function(response) {
      res(response.data);
    })
    .catch(function(error) {
      rej(errorResponseHandler(error.response));
    });
  });
}

export function updateEvent(event_id, meta) {
  return new Promise((res, rej) => {
    axios.put(store.state.url + "/api/v1/events/" + event_id, meta, store.state.credentials)
    .then(function(response) {
      res(response.data);
    })
    .catch(function(error) {
      rej(errorResponseHandler(error.response));
    });
  });
}

export function getAllEvents(params, page) {
  source = CancelToken.source();
  let requestData = {
    headers: store.state.credentials.headers
  };
  if (params !== undefined) {
    requestData.params = params;
  }
  if (page !== undefined) {
    requestData.params.page = page;
  }
  return new Promise((res, rej) => {
    axios.get(store.state.url + "/api/v1/events",
      {
        headers: requestData.headers,
        params: requestData.params,
        cancelToken: source.token,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
    .then(function(response) {
      res({
        data: response.data
      });
    })
    .catch(function(error) {
      rej(errorResponseHandler(error.response));
    });
  });
}


export function viewEvent(event_id) {
  return new Promise((res, rej) => {
    axios.get(store.state.url + "/api/v1/events/" + event_id, store.state.credentials)
    .then(function(response) {
      res({
        data: response.data
      });
    })
    .catch(function(error) {
      rej(error.response);
    });
  });
}

export function deleteEvent(event_id) {
  return new Promise((res, rej) => {
    axios.delete(store.state.url + "/api/v1/events/" + event_id, store.state.credentials)
    .then(function(response) {
      let data = response.data;
      res({
        data: data
      });
    })
    .catch(function(error) {
      rej(error.response.data);
    });
  });
}

export function getBookedSeats(eventId) {
  return new Promise((resolve, reject) => {
    axios.get(`${store.state.url}/api/v1/events/${eventId}/schema/seats/`, store.state.credentials)
    .then(response => {
      resolve(response);
    })
    .catch(error => {
      reject(errorResponseHandler(error));
    });
  });
}

export function checkForAccount(email) {
  return new Promise((resolve, reject) => {
    axios.get(`${store.state.url}/api/v1/check-for-account?email=${email}`)
    .then(function (response) {
      resolve({
        data: response.data,
      });
    })
    .catch(error => {
      reject(errorResponseHandler(error));
    });
  });
}

export function oneStepCreation(data, token) {
  return new Promise((resolve, reject) => {
    axios.post(`${store.state.url}/api/v1/events/one-step-creation`,
      data,
      token && store.state.credentials,
    )
    .then(function (response) {
      resolve({
        data: response.data,
      });
    })
    .catch(error => {
      reject(errorResponseHandler(error));
    });
  });
}

export function uploadFiles(files) {
  return new Promise((res, rej) => {
    axios.post(`${store.state.url}/api/v1/upload`, files, store.state.credentials)
    .then(function(response) {
      res({
        data: response.data
      });
    })
    .catch(function(error) {
      rej(errorResponseHandler(error.response));
    });
  })
}