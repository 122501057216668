import axios from 'axios'
import store from '../../store';
import errorResponseHandler from '../responseError/errorResponseHandler'

export function postAnswer(meta) {
    return new Promise((res, rej) => {
        axios.post(store.state.url + '/api/v1/whyRte', meta, store.state.credentials)
            .then(function (response) {
                res(response.data);
            })
            .catch(function (error) {
                rej(errorResponseHandler(error.response));
            });
    });
}