<template>
<!--  <span class="text-center">-->
    <div class="dropdown combineTableDropdownButton"
         v-click-outside="onClickOutsideSettingSubMenu"
         data-toggle="dropdown"
         ref="dropdownWrapper" style="position: static;">
<!--      <a data-toggle="dropdown" class="btn btn-sm btn-clean btn-icon btn-icon-md" v-on:click="activate">-->
<!--        <i class="la la-reorder"/>-->
<!--      </a>-->

      <!-- activatedMenuId == eventId -->
      <div class="dropdown-menu dropdown-menu-right"
           :class="{ show : activatedMenuId }"
           ref="dropdownElement"
           :style="`left:calc(${coordX}px - ${deviation}px);top:${coordY}px;right: auto;`">
        <router-link :to="'/event/' + eventId" class="dropdown-item" v-if="user.role.id != '7' && user.role.id != '9'">
          <i class="la la-eye"/>
          {{$t('menu.eventDetails')}}
        </router-link>

        <router-link :to="'/event/' + eventId + '/edit'" class="dropdown-item" v-if="user.role.id != '7' && user.role.id != '9'">
          <i class="la la-edit"/>
          {{$t('menu.editDetails')}}
        </router-link>

        <router-link :to="'/event/' + eventId + '/tickets'" class="dropdown-item" v-if="user.role.id != '7' && user.role.id != '9'">
          <i class="la la-ticket"/>
          {{$t('menu.ticketSchemas')}}
        </router-link>

        <router-link :to="'/event/' + eventId + '/reg-forms'" class="dropdown-item" :class="{'disabled': event && event.fill_step && event.fill_step.ticket_schema_step === 0}" v-if="user.role.id != '9' && user.role.id != '7'">
          <i class="la la-list"/>
          {{$t('menu.forms')}}
        </router-link>

        <router-link :to="'/event/' + eventId + '/integration-links'" class="dropdown-item" :class="{'disabled': event && event.fill_step && event.fill_step.ticket_schema_step === 0}" v-if="user.role.id != '9'">
          <i class="la la-link"/>
          {{$t('menu.links')}}
        </router-link>

        <router-link :to="'/event/' + eventId + '/payments'" class="dropdown-item" v-if="user.role.id != '7' && user.role.id != '9'">
          <i class="la la-money"/>
          {{$t('menu.payment')}}
        </router-link>

        <router-link :to="'/event/' + eventId+ '/event-settings'" class="dropdown-item" v-if="user.role.id != '7' && user.role.id != '9'">
          <i class="la la-cog"/>
          {{$t('menu.event-settings')}}
        </router-link>

        <router-link :to="'/event/' + eventId + '/promocodes'" class="dropdown-item" :class="{'disabled': event && event.fill_step && event.fill_step.ticket_schema_step === 0}" v-if="user.role.id != '9' || (user.role.id == '9' && user.promocode_access)">
          <i class="la la-tags"/>
          {{$t('menu.promocodes')}}
        </router-link>

        <router-link :to="'/event/' + eventId + '/streams'" class="dropdown-item" v-if="user.role.id != '7' && user.role.id != '9'"
                     :class="{'disabled': event && event.fill_step && event.fill_step.ticket_schema_step === 0}">
          <i class="la la-table"/>
          {{$t('menu.streams')}}
        </router-link>

        <router-link :to="'/event/' + eventId + '/landing'" class="dropdown-item" v-if="user.role.id != '7' && user.role.id != '9'"
                     :class="{'disabled': event && event.fill_step && event.fill_step.ticket_schema_step === 0}">
          <i class="la la-laptop"/>
          {{$t('menu.eventPage')}}
        </router-link>

        <router-link :to="'/event/' + eventId + '/mobile-app/feed'"
                     class="dropdown-item"
                     v-if="(user.role.id != '7' && user.role.id != '9' && user.role.id != '5')">
          <i class="la la-mobile"/>
          {{$t('menu.mobileApp')}}
        </router-link>
        <router-link :to="'/event/' + eventId + '/telegram-bot'"
                     class="dropdown-item"
                     v-if="user.role.id != '7' && user.role.id != '9' && (user.role.id == '1' || user.role.id == '2')">
          <i class="la la-android"/>
          Telegram-бот
        </router-link>
        <div class="dropdown-item" style="cursor: pointer" @click="openCopyConfirmation" v-if="user.role.id != '9' && user.role.id != '7'">
          <i class="la la-copy"/>
          {{$t('menu.copyEvent')}}
        </div>

      </div>
    </div>
<!--  </span>-->
</template>

<script>
import vClickOutside from "v-click-outside";

export default {
  name: "EventNavigationMenu",

  props: {
    event: {
      required: true
    },
    eventId: {
      required: true
    },
    activatedMenuId: {
      required: true
    },
    coordX: {
      required: false
    },
    coordY: {
      required: false
    },
    place: {
      required: false
    }
  },

  data() {
    return {
      active_setting: null,
      activated: false,
      menuWidth: '',
      deviation: 234,
      domain: ''
    }
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  mounted() {
    this.domain = window.location.origin

    let userAgent = navigator.userAgent.toLowerCase();
    let Android = /android/.test(userAgent);
    let IOS = /iphone/.test(userAgent);
    let webos = /webos/.test(userAgent);
    if (this.place === 'subheader' && !Android && !IOS && !webos) this.deviation = 500
    else this.deviation = 234
  },

  computed: {
    user() {
      return this.$store.getters.user
    }
  },

  methods: {
    activate() {
      this.$emit('setActivatedMenuId', this.eventId)

      const tablePos = document.querySelector('#eventTable').getBoundingClientRect().top
      const buttonWrapperPos = this.$refs.dropdownWrapper.getBoundingClientRect().top
      const buttonPos = (buttonWrapperPos - tablePos) + 20
      const menuHeight = 361

      if (buttonPos > menuHeight) {
        this.$refs.dropdownElement.style.top = 'auto'
        this.$refs.dropdownElement.style.bottom = '100%'
      }
    },

    onClickOutsideSettingSubMenu(e) {
      let screenWidth = window.innerWidth;
      if (e.target.parentNode && !e.target.parentNode.hasAttribute('data-toggle') && screenWidth > 900) {
        this.$emit('setActivatedMenuId', '')
      }
    },
    openCopyConfirmation() {
      this.$swal.fire({
        title: this.$t('general.modal.areYouSure'),
        text: this.$t('general.modal.copyHell'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#aaaaaa',
        cancelButtonColor: '#4bb76c',
        cancelButtonText: this.$t('general.actions.notCopy'),
        confirmButtonText: this.$t('general.actions.yes'),
        reverseButtons: true,
      }).then(res => {
        if (res.value) {
          this.$router.push('/event/' + this.eventId + '/copy-event')
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .dropdown-menu {
    position: absolute !important
  }
  .dropdown-menu-right {
    min-width: 18rem!important;
    margin-right: -.8rem!important;
    margin-top: .5rem!important;
    //left: auto!important;
    //right: 0!important;
    z-index: 10!important;
  }
  .dropdown {
    .btn {
      cursor: pointer;
    }
  }
  .event-navigation {
    display: flex;
    align-items: center;
    .btn {
      margin: 0
    }
    .la-reorder {
      font-size: 1.5rem !important
    }
  }

</style>