import axios from "axios";
import store from "../../store";
import errorResponseHandler from "../responseError/errorResponseHandler";

let qs = require("qs");

const CancelToken = axios.CancelToken;
let source;

export function cancelRequest() {
  if (source) source.cancel("canceled");
}

export function getAllCheckins(params) {
  source = CancelToken.source();
  let requestData = {};
  requestData.headers = store.state.credentials.headers;
  if (params != undefined) {
    requestData.params = params;
  }
  return new Promise((res, rej) => {
    axios
      .get(store.state.url + "/api/v1/events/orders/tickets/check-ins", {
        headers: requestData.headers,
        params: requestData.params,
        cancelToken: source.token,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      })
      .then(function (response) {
        res({
          data: response.data,
        });
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          rej({ data: { message: error.message } });
        } else {
          rej(errorResponseHandler(error.response));
        }
      });
  });
}

export function getCheckinsDashboard() {
  return new Promise((res, rej) => {
    axios
      .get(
        store.state.url +
          "/api/v1/events/orders/tickets/check-ins/dashboard-statistic",
        store.state.credentials,
      )
      .then(function (response) {
        res({
          data: response.data,
        });
      })
      .catch(function (error) {
        rej(errorResponseHandler(error.response));
      });
  });
}

export function getSticker(eventId, orderId, ticketId) {
  return new Promise((res, rej) => {
    axios
      .post(
        store.state.url +
          "/api/v1/events/" +
          eventId +
          "/orders/" +
          orderId +
          "/tickets/" +
          ticketId +
          "/holder/generatePdfSticker",
        {},
        store.state.credentials,
      )
      .then(function (response) {
        res({
          data: response.data,
        });
      })
      .catch(function (error) {
        rej(errorResponseHandler(error.response));
      });
  });
}

export function getStickersForPaidOrders(eventId, params) {
  let requestData = {};
  requestData.headers = store.state.credentials.headers;
  if (params != undefined) {
    requestData.params = params;
  }
  return new Promise((res, rej) => {
    axios
      .get(
        store.state.url +
          "/api/v1/events/" +
          eventId +
          "/generate-sticker-pdf-for-paid-tickets",
        {
          headers: requestData.headers,
          params: requestData.params,
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
        },
      )
      .then(function (response) {
        res({
          data: response.data,
        });
      })
      .catch(function (error) {
        rej(errorResponseHandler(error.response));
      });
  });
}

export function checkinTicket(eventId, orderId, ticketId, meta) {
  return new Promise((res, rej) => {
    axios
      .post(
        store.state.url +
          "/api/v1/events/" +
          eventId +
          "/orders/" +
          orderId +
          "/tickets/" +
          ticketId +
          "/check-ins",
        meta,
        store.state.credentials,
      )
      .then(function (response) {
        res({
          data: response.data,
        });
      })
      .catch(function (error) {
        rej(errorResponseHandler(error.response));
      });
  });
}

export function checkoutAll(eventId) {
  return new Promise((res, rej) => {
    axios
      .get(
        store.state.url +
          "/api/v1/events/" +
          eventId +
          "/orders/tickets/check-ins/check-out-all",
        store.state.credentials,
      )
      .then(function (response) {
        res({
          data: response.data,
        });
      })
      .catch(function (error) {
        rej(errorResponseHandler(error.response));
      });
  });
}

export function exportCheckins(eventId) {
  return new Promise((res, rej) => {
    axios
      .get(
        store.state.url +
          "/api/v1/events/" +
          eventId +
          "/orders/tickets/check-ins/make-check-ins-report",
        store.state.credentials,
      )
      .then(function (response) {
        res({
          data: response.data,
        });
      })
      .catch(function (error) {
        rej(errorResponseHandler(error.response));
      });
  });
}

export function getPrintCount(eventId, params) {
  source = CancelToken.source();
  let requestData = {};
  requestData.headers = store.state.credentials.headers;
  if (params != undefined) {
    requestData.params = params;
  }
  return new Promise((res, rej) => {
    axios
      .get(store.state.url + "/api/v1/events/" + eventId + "/print/unchecked", {
        headers: requestData.headers,
        params: requestData.params,
        cancelToken: source.token,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      })
      .then(function (response) {
        res({
          data: response.data,
        });
      })
      .catch(function (error) {
        rej(errorResponseHandler(error.response));
      });
  });
}
export function printAll(eventId , params) {
  return new Promise((res, rej) => {
    axios
      .post(
        store.state.url + "/api/v1/events/" + eventId + "/print/unchecked",
        params,
        {
          headers: store.state.credentials.headers,
          params: {},
          responseType: "blob",
        },
      )
      .then(function (response) {
        res({
          data: response.data,
        });
      })
      .catch(function (error) {
        rej(errorResponseHandler(error.response));
      });
  });
}
