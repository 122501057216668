<template>
  <!--begin: User Bar -->
  <div class="kt-header__topbar-item kt-header__topbar-item--user" :class="{'show': showUserModal}"
       v-click-outside="onClickOutside" v-if="!loading">
    <div class="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="0px,0px"
         v-on:click="showUserModal = !showUserModal">
      <div class="kt-header__topbar-user">
                <span class="kt-header__topbar-welcome kt-hidden-mobile">
                  {{ $t('general.welcome') }},
                </span>
        <span class="kt-header__topbar-username kt-hidden-mobile">
                  {{ user.email == null ? "Guest" : user.email }}
                </span>
        <span class="kt-header__topbar-user--image" v-if="userData && userData.avatar">
                  <img :src="userData.avatar" alt="">
                </span>
        <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
        <span
            class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold"
            v-else>
                  {{ user.email == null ? 'G' : user.email.charAt(0) }}
                </span>
      </div>
    </div>
    
    <div
        class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl user-bar-modal"
        :class="{'show': showUserModal}">
      <!--begin: Head -->
      <div class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
           style="background-image: url(/assets/media/misc/bg-1.jpg)">
        <div class="kt-user-card__avatar" style="min-width: 60px;" v-if="userData">
          <img :src="userData.avatar" alt="" v-if="userData.avatar">
          <span class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success" v-else>
                      <template v-if="userData.first_name">{{ userData.first_name.charAt(0) }}</template>
                      <template v-else>{{ userData.email.charAt(0) }}</template>
                    </span>
        </div>
        <div class="kt-user-card__name" v-if="!loading">
          <template v-if="userData">
            <div v-if="userData.first_name">{{ userData.first_name }} {{ userData.last_name }}</div>
            <div v-else>{{ userData.email }}</div>
            <span
                class="kt-badge kt-badge--success  kt-badge--brand kt-badge--inline kt-badge--pill no-wrap font-weight-normal">
                      {{ userData.role.name }}
                    </span>
          </template>
          <template v-else>Ошибка при загрузке данных пользователя. Обратитесь к администратору.</template>
        </div>
        <!--                <div class="kt-user-card__name" v-else>-->
        <!--                  {{userData.first_name}} {{userData.last_name}}-->
        <!--                </div>-->
      </div>
      <!--end: Head -->
      
      <!--begin: Navigation -->
      <div class="kt-notification">
        <router-link to="/profile/personal-information" class="kt-notification__item">
          <div class="kt-notification__item-icon">
            <i class="flaticon2-calendar-3 kt-font-success"></i>
          </div>
          <div class="kt-notification__item-details">
            <div class="kt-notification__item-title kt-font-bold">
              {{ $t('menu.profile') }}
            </div>
            <div class="kt-notification__item-time">
              {{ $t('userMenu.profileDescription') }}
            </div>
          </div>
        </router-link>
        <a href="#" class="kt-notification__item"
           @click.prevent="showPrintModal = true"
           v-if="user.role.role === 'admin' || user.role.role === 'registrar'">
          <div class="kt-notification__item-icon">
            <i class="flaticon2-printer kt-font-success"></i>
          </div>
          <div class="kt-notification__item-details">
            <div class="kt-notification__item-title kt-font-bold">
              Напечатать стикеры
            </div>
            <div class="kt-notification__item-time">
              Печать стикеры для людей, которые еще не пришли
            </div>
          </div>
        </a>
        <!--              <router-link to="/balance" class="kt-notification__item">-->
        <!--                <div class="kt-notification__item-icon">-->
        <!--                  <i class="flaticon-coins kt-font-success"></i>-->
        <!--                </div>-->
        <!--                <div class="kt-notification__item-details">-->
        <!--                  <div class="kt-notification__item-title kt-font-bold">-->
        <!--                    Мой баланс-->
        <!--                  </div>-->
        <!--                  <div class="kt-notification__item-time">-->
        <!--                    {{userData.mail_credit_balance}}-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </router-link>-->
        <div class="kt-notification__custom kt-space-between">
          <a href="#"
             target="_blank"
             v-on:click="signOut($event)"
             class="btn btn-label btn-label-brand btn-sm btn-bold">
            {{ $t('general.signOut') }}
          </a>
        </div>
      </div>
      <!--end: Navigation -->
    </div>
    
    <div class="text-center mt-5" v-if="user.role.role === 'admin' || user.role.role === 'registrar'">
      <PrintModal v-model="showPrintModal"/>
    </div>
  </div>
</template>

<script>
import PrintModal from '@/components/checkin/PrintModal.vue'
import vClickOutside from 'v-click-outside'

export default {
  name: 'UserBar',
  components: {
    PrintModal
  },
  props: {
    languages: Array,
    userData: Object,
    loading: Boolean
  },
  data() {
    return {
      showPrintModal: false,
      showUserModal: false
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    userId() {
      return localStorage.getItem('userId')
    },
    userRole() {
      return localStorage.getItem('userRoleRTE')
    }
  },
  methods: {
    onClickOutside() {
      this.showUserModal = false;
    },
    signOut(e) {
      e.preventDefault();
      this.$store.dispatch('logOut');
      this.$router.push({path: '/auth'});
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
}
</script>

<style lang="sass">
.user-bar-modal
  left: auto
  right: 15px

.kt-header__topbar-user
  img
    max-width: 34px
    width: 34px
    height: 34px
    border-radius: 50% !important

.kt-user-card__avatar
  height: 60px
  width: 60px
  position: relative
  overflow: hidden
  border-radius: 50%
  
  img
    width: 100%
    height: 100%
    border-radius: 4px
    position: absolute
    inset: 0
    object-fit: cover
    object-position: center
</style>