<template>
  <div class="kt-header__topbar-item kt-header__topbar-item--langs" :class="{'show': showBalance}" v-click-outside="onClickOutside">
    <button class="btn btn-label btn-label-brand btn-bold" data-toggle="dropdown" data-offset="10px,0px" v-on:click="showBalance = !showBalance">Balance</button>
    <div class="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="10px,0px" v-on:click="showBalance = !showBalance" >
    
    </div>
    <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround balance-modal" :class="{'show': showBalance}">
      <Loading v-if="loading" />
      <template v-else>
        <ul class="kt-nav">
          <li class="kt-nav__item px-4 py-2">
            Unisender: <span>{{unisender}}</span>
          </li>
          <li class="kt-nav__item px-4 py-2">
            TurboSms: <span>{{turboSms}}</span>
          </li>
          <li class="kt-nav__item px-4 py-2">
            ESputnik: <span>{{esputnik}}</span>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import { getBalanceForAdmin } from "@/helpers/balance/balance.js";

export default {
  name: "AdminBalance",
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      loading: false,
      showBalance: false,
      esputnik: null,
      turboSms: null,
      unisender: null,
    }
  },
  created() {
    this.loading = true
    getBalanceForAdmin().then(res => {
      if (res.data) {
        this.esputnik = res.data.esputnik.balance
        this.turboSms = res.data.turboSms.balance
        this.unisender = res.data.unisender.balance
      }
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {
    onClickOutside () {
      this.showBalance = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.balance-modal
  left: auto
  right: 135px
.kt-nav__item
  span
    font-weight: 500
</style>