<template>
  <b-modal id="qr-scanner" size="xl" v-model="showScanner" hide-footer centered @hidden="closeScanner">
    <template #modal-header>
      <h5 class="modal-title">Scanning...</h5>
      <div>
      <button v-if="isCameraReady && devices.length > 1" class="btn btn-sm btn-label btn-icon btn-switch" @click="switchCamera">
        <i class="bi bi-arrow-repeat" style="font-size: 1.5em;"></i>
      </button>
      <button class="close ml-2" @click="showScanner=false"></button>
      </div>
    </template>
    <qrcode-stream :camera="facingMode" @init="onCameraOn" @detect="onDetect">

    </qrcode-stream>
  </b-modal>
</template>

<script>
export default {
  name: "WebQrScanner",
  data() {
    return {
      selectedDevice: null,
      showScanner: false,
      facingMode: 'auto',
      devices: [],
      isCameraReady: false,
    }
  },
  emits: ['scanned-code', 'close'],
  props: {
    showWebScanner: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.getDevices()
  },
  watch: {
    showWebScanner() {
      this.showScanner = this.showWebScanner
    }
  },
  methods: {
    onCameraOn(){
      this.isCameraReady = true
    },
    async getDevices() {
      this.devices = (await navigator.mediaDevices.enumerateDevices()).filter(({ kind }) => kind === 'videoinput')

      if (this.devices.length > 0) {
        this.selectedDevice = this.devices[0]
      }
    },
    switchCamera() {
      switch (this.facingMode) {
        case 'auto':
          this.facingMode = 'front'
          break
        case 'front':
          this.facingMode = 'auto'
          break
      }
    },
    onDetect(result) {
      this.showScanner = false
      this.$emit('scanned-code', result)
    },
    toggleScanner() {
      this.showScanner = !this.showScanner
    },
    closeScanner() {
      this.showScanner = false
    }
  }
}
</script>

<style scoped lang="scss">
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
</style>
<style  lang="scss">
#qr-scanner .modal-body{
  padding-top: 0!important;
}
#qr-scanner .modal-header{
  border-bottom: none;
}
</style>