<template>
  <b-modal id="storage-modal" v-model="show" size="xl" hide-footer hide-header @hidden="$store.dispatch('toggleStorage', false)">
    <!--    storage content-->
    <div class="storage__content" v-if="!filesLoading">
      <div class="storage__info">
        <div class="storage__course-list">
          <label class="btn btn-success btn-block storage__add mb-4">
            <input class="d-none" type="file" ref="storageAdd" @change="addFile">
            <i class="flaticon2-plus"></i> {{$t('general.actions.add')}}
          </label>
        </div>
<!--        <div class="storage__memory" v-if="!filesLoading && maxCapacity">-->
<!--          <span class="storage__memory&#45;&#45;indicator">-->
<!--            <span class="storage__memory&#45;&#45;process" :style="'width: ' + ((maxCapacity - freeCapacity)/maxCapacity) * 100 + '%'"></span>-->
<!--          </span>-->
<!--          <p class="m-0">{{$t('storage.used')}}</p>-->
<!--          <p class="m-0">-->
<!--            <span :class="{'text-danger': (((maxCapacity - freeCapacity)/maxCapacity) * 100) > 90}">-->
<!--              {{convertSize(maxCapacity - freeCapacity)}}-->
<!--            </span> {{$t('storage.from')}}-->
<!--            {{convertSize(maxCapacity)}}</p>-->
<!--        </div>-->
      </div>
      <div class="storage__body">
        <div class="storage__top">
          <h3 class="text-dark">{{$t('storage.title')}}</h3>
          <button class="btn btn-icon btn-light storage__close" @click="$store.dispatch('toggleStorage')">
            <i class="la la-remove"></i>
          </button>
        </div>
        <div class="storage__menu">
          <div>
            <button class="btn btn-sm btn-icon btn-label-brand mr-2"
                    @click="handleCreateFolder">
              <i class="la la-plus"></i>
            </button>
            <button class="btn btn-sm btn-icon btn-label-brand mr-2"
                    v-if="chosenFile || selectedFolder"
                    @click="setUpdatable">
              <i class="la la-edit"></i>
            </button>
            <button class="btn btn-sm btn-icon btn-label-danger"
                    v-if="chosenFile || selectedFolder"
                    @click="selectedFolder ? handleDeleteFolder() : handleDeleteFile($event)">
              <i class="la la-trash"></i>
            </button>
          </div>
          <div>
            <button class="btn btn-sm btn-icon btn-label-brand mr-3"
                    @click="changeSort">
              <i :class="'la la-sort-amount-' + sort"></i>
            </button>
            <span class="text-primary font-weight-bold">{{$t('storage.filesLength')}}: {{files.length}} {{$t('storage.countInch')}}</span>
          </div>
          <div class="w-100 mt-3" v-if="(chosenFile && chosenFile.updatable) || (selectedFolder && selectedFolder.updatable)">
            <div class="form-group d-flex align-items-center m-0">
              <input type="text"
                     class="form-control"
                     v-model="newName"
                     @keyup.enter="chosenFile ? handleUpdateFile() : handleUpdateFolder()">
              <button class="btn btn-icon btn-success ml-3"
                      @click="chosenFile ? handleUpdateFile() : handleUpdateFolder()">
                <i class="la la-check"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="storage__files-wrapper">
          <div class="storage__crumbs">
            <div class="storage__crumb-item root">
              <a href="#" @click.prevent="openFolder('')">
                <i class="la la-home kt-font-brand"></i>
                root
              </a>
            </div>
            <div class="storage__crumb-item root" v-if="parentFolder">
              <a href="#" @click.prevent="openFolder(parentFolder)">
                {{parentFolder}}
              </a>
            </div>
            <div class="storage__crumb-item" v-if="currentFolder">
              <span class="fw-500">{{currentFolder.folder_name}}</span>
            </div>
          </div>
          <div class="storage__files" v-if="!filesLoading && !folderLoading">
            <template v-if="folders.length">
              <div class="storage__file-item folder"
                   :class="{'chosen': selectedFolder === folder}"
                   v-for="(folder,i) in folders"
                   :key="folder+'_'+i"
                   @click="selectFolder(folder)"
                   @dblclick="openFolder(folder)">
                <div class="storage__item-preview">
                  <i class="flaticon-folder"></i>
                </div>
                <div class="storage__item-foot">
                  <p class="m-0 text-truncate text-dark" :title="folder">{{folder.folder_name}}</p>
                </div>
              </div>
            </template>
            <template v-if="files.length">
              <div class="storage__file-item"
                   :class="{'chosen': chosenFile === file || (Array.isArray(chosenFile) ? chosenFile.includes(file) : '')}"
                   v-for="file in files"
                   :key="file.id"
                   @click="selectFile($event,file)">
                <div class="storage__item-preview">
                  <template v-if="fileType(file) === 'image'">
                    <img :src="file.path" :alt="file.file_name">
                  </template>
                  <template v-else>
                    <div class="no-image text-dark">
                      {{file.extension}}
                    </div>
                  </template>
                </div>
                <div class="storage__item-foot">
                  <p class="m-0 text-truncate text-dark" :title="file.file_name">{{file.file_name}}</p>
                </div>
                <div class="storage__file-size">
                  <span v-if="fileType(file) === 'image'">{{file.extension}}, </span>
                  <span>{{getFileSize(file)}}</span>
                </div>
              </div>
            </template>
            <div class="w-100 h-100 d-flex flex-column align-items-center justify-content-center" v-if="!folders.length && !files.length">
              <img :src="noData" alt="no-data">
              <h3 class="my-3">{{$t('general.nothingFound')}}</h3>
              <label class="btn btn-success">
                <input class="d-none" type="file" ref="storageAdd" @change="addFile">
                <i class="flaticon2-plus"></i> {{$t('general.actions.add')}}
              </label>
            </div>
          </div>
          <Loading style="flex-grow: 1" v-else />
          <div class="loading-file" v-if="$store.getters.uploadProgress.percent > 0">
            <p class="text-dark font-weight-bold mb-0">{{$t('storage.loadProgress')}} </p>
            <div class="loading-file-process">
              <span class="process-line" :style="'width:' + $store.getters.uploadProgress.percent + '%'"></span>
              <span class="process-text text-dark">{{$store.getters.uploadProgress.percent}}%</span>
            </div>
            <p class="mb-0 text-dark">{{getFileSize($store.getters.uploadProgress.loaded)}} с {{getFileSize($store.getters.uploadProgress.total)}}</p>
          </div>
        </div>
        <div class="storage__bottom" v-if="!filesLoading">
<!--          <template v-if="chosenFile || (Array.isArray(chosenFile) ? chosenFile.length : '')">-->
<!--            <button class="btn btn-danger" @click="deleteFile">{{$t('general.actions.delete')}}</button>-->
<!--          </template>-->
          <div class="flex-grow-1"></div>
          <button type="button"
                  class="btn btn-light-primary font-weight-bold"
                  data-dismiss="modal"
                  @click="$store.dispatch('toggleStorage')">{{$t('general.actions.close')}}</button>
          <button type="button"
                  class="btn btn-brand font-weight-bold"
                  @click="$store.dispatch('selectFile', chosenFile)"
                  v-if="storageTarget && (chosenFile || (Array.isArray(chosenFile) ? chosenFile.length : ''))">Ок</button>
        </div>
      </div>
    </div>
    <Loading v-else />
    <!--    end storage content-->
  </b-modal>
</template>

<script>
import {
  addFileToStorage,
  createFolder,
  deleteFile,
  deleteFolder,
  getUserFiles, updateFile, updateFolder
} from "@/helpers/storage/storage";
import pdf from '@/assets/files/pdf.svg'
import noData from '@/assets/nodata.png'

export default {
  name: "StorageModal",
  data() {
    return {
      newName: '',
      pdf, noData,
      show: true,
      courseLoading: false,
      filesLoading: true,
      folderLoading: false,
      courses: [],
      chosenCourse: null,
      files: [],
      folders: [],
      chosenFile: null,
      freeCapacity: 0,
      maxCapacity: 0,
      multi: 0,
      sort: 'desc',
      currentFolder: '',
      parentFolder: null,
      selectedFolder: null
    }
  },
  computed: {
    url() {
      return this.$store.getters.url
    },
    user() {
      return this.$store.getters.user
    },
    storageTarget() {
      return this.$store.getters.storageTarget
    }
  },
  created() {
    if (localStorage.getItem('storageSort')) {
      this.sort = localStorage.getItem('storageSort')
    }
    this.filesLoading = true
    this.loadFiles()
  },
  methods: {
    loadFiles(e) {
      const params = {
        sort: this.sort
      }
      if (this.currentFolder) {
        params.folder = this.currentFolder.id
      }
      getUserFiles(params).then(response => {
        let res = response.data
        if (response.data.data) {
          res = response.data.data
        }
        this.folders = res.folders
        // this.currentFolder = res.current_folder
        this.parentFolder = res.parent_folder
        if (this.storageTarget) {
          let allowedImages = ['jpg','jpeg', 'png', 'svg', 'webp', 'gif']
          let allowedVideos = ['mp4', 'avi', 'mkv', 'MOV', 'FLV', 'wmv']
          let allowedAudio = ['mp3', 'm4a', 'aac', 'wav', 'wma', 'flac', 'ogg']
          let target = ''
          if (typeof this.storageTarget === 'string') {
            target = this.storageTarget
          } else {
            target = this.storageTarget.type
            this.multi = 1
          }
          switch(target) {
            case 'image':
              this.files = []
              res.files.forEach(file => {
                if (allowedImages.includes(file.extension)) this.files.push(file)
              })
              break
            case 'video':
              this.files = []
              res.files.forEach(file => {
                if (allowedVideos.includes(file.extension)) this.files.push(file)
              })
              break
            case 'audio':
              this.files = []
              res.files.forEach(file => {
                if (allowedAudio.includes(file.extension)) this.files.push(file)
              })
              break
            case 'file':
              this.files = []
              res.files.forEach(file => {
                this.files.push(file)
              })
              break
          }
        } else {
          this.files = res.files
        }
        this.chosenFile = null
        if (response.data.capacity) {
          this.freeCapacity = response.data.capacity
        }
        if (response.data.max_capacity) {
          this.maxCapacity = response.data.max_capacity
        }
      }).catch(error => {
        this.$swal(this.$t("general.error"), error.data.message, "error")
      }).finally(() => {
        this.filesLoading = false
        this.folderLoading = false
        e ? e.target.disabled = false : null
      })
    },
    openFolder(folder) {
      this.folderLoading = true
      this.currentFolder = folder
      this.loadFiles(null)
    },
    handleCreateFolder() {
      const meta = {
        folder_name: 'New folder'
      }
      createFolder(meta).then(res => {
        this.folders.push(res.data)
      })
    },
    handleUpdateFolder() {
      const meta = {
        folder_name: this.newName
      }
      this.selectedFolder.updatable = false
      this.selectedFolder.folder_name = this.newName
      this.$forceUpdate()
      updateFolder(this.selectedFolder.id, meta)
    },
    handleDeleteFolder() {
      deleteFolder(this.selectedFolder.id)
    },
    setUpdatable() {
      if (this.selectedFolder) {
        this.selectedFolder.updatable = true
        this.newName = this.selectedFolder.folder_name
      } else {
        this.chosenFile.updatable = true
        this.newName = this.chosenFile.file_name
      }
      this.$forceUpdate()
    },
    addFile(e) {
      let meta = new FormData()
      let files = e.target.files || e.dataTransfer.files
      if (files.length) {
        if (files[0].size < this.maxCapacity || this.user.role.role === 'admin') {
          meta.append('file', files[0])
          if (this.currentFolder) {
            meta.append('folder_id', this.currentFolder.id)
          }

          addFileToStorage(meta).then(() => {
            this.loadFiles()
          }).catch(error => {
            this.$swal(this.$t('general.error'), error.data.message, "error")
          })
        } else {
          this.$swal({
            title: this.$t("general.error"),
            text: 'Недостаточно места',
            icon: "error",
            showCancelButton: true,
            confirmButtonColor: '#0abb87',
            confirmButtonText: `Сменить тариф`,
            cancelButtonText: this.$t('general.actions.cancel'),
          }).then((result) => {
            if (result.value) {
              this.$router.push(`/rates`)
              this.$store.dispatch('toggleStorage')
            }
          })
        }
      }
    },
    convertSize(size) {
      if (!size) return '-'
      let kb = size / 1000
      if (kb < 1000 ) {
        return kb.toFixed(2) + ' KB'
      } else {
        let mb = kb / 1000
        if (mb < 1000) {
          return mb.toFixed(2) + ' MB'
        } else {
          let gb = mb / 1000
          return (gb^0) === gb ? gb.toString() + ' GB' : gb.toFixed(1) + ' GB'
        }
      }
    },
    getFileSize(file) {
      if (!file) return '-'
      let size = file.file_size || file
      let kb = size / 1024
      if (kb < 1024 ) {
        return kb.toFixed(2) + ' KB'
      } else {
        let mb = kb / 1024
        return mb.toFixed(2) + ' MB'
      }
    },
    handleUpdateFile() {
      const meta = {
        file_name: this.newName
      }
      this.chosenFile.updatable = false
      this.chosenFile.file_name = this.newName
      this.$forceUpdate()
      updateFile(this.chosenFile.id, meta)
    },
    handleDeleteFile(e) {
      e.target.disabled = true
      if (Array.isArray(this.chosenFile)) {
        this.chosenFile.forEach((chosenFile,i) => {
          deleteFile(chosenFile.id).then(() => {
            if ((i+1) === this.chosenFile.length) {
              this.loadFiles(this.chosenCourse)
            }
          }).catch(error => {
            this.$swal(this.$t("general.error"), error.data.message, "error")
          }).finally(() => e.target.disabled = false)
        })
      } else {
        deleteFile(this.chosenFile.id).then(() => {
          this.loadFiles(this.chosenCourse)
        }).catch(error => {
          this.$swal(this.$t("general.error"), error.data.message, "error")
        }).finally(() => e.target.disabled = false)
      }
    },
    selectFolder(folder) {
      this.chosenFile = null
      if (this.selectedFolder) {
        this.selectedFolder.updatable = false
      }
      this.selectedFolder = folder
    },
    selectFile(e, file) {
      this.selectedFolder = ''
      if (this.chosenFile) {
        this.chosenFile.updatable = false
      }
      if (this.storageTarget === 'file' || this.multi) {
        if (!Array.isArray(this.chosenFile)) this.chosenFile = []
        if (Array.isArray(this.chosenFile) && this.chosenFile.includes(file)) {
          this.chosenFile.splice(this.chosenFile.indexOf(file), 1)
        } else this.chosenFile.push(file)
      } else this.chosenFile = file
    },
    fileType(file) {
      if (file.extension === 'jpg' ||
          file.extension === 'jpeg' ||
          file.extension === 'png' ||
          file.extension === 'svg' ||
          file.extension === 'gif' ||
          file.extension === 'webp') {
        return 'image'
      } else if (file.extension === 'mp4' ||
          file.extension === 'avi' ||
          file.extension === 'mkv' ||
          file.extension === 'MOV' ||
          file.extension === 'FLV') {
        return 'video'
      } else if (file.extension === 'pdf') {
        return 'pdf'
      } else return 'file'
    },
    changeSort(e) {
      e.target.disabled = true
      this.sort === 'asc' ? this.sort = 'desc' : this.sort = 'asc'
      this.files = this.files.reverse()
      localStorage.setItem('storageSort', this.sort)
      this.loadFiles(e)
    }
  }
}
</script>

<style lang="sass">
#storage-modal
  z-index: 9998 !important
.storage__empty
  position: relative
  button
    top: 30px
    right: 25px
.loading-file
  position: absolute
  bottom: 0
  left: 0
  right: 0
  background: #f7f7f7
  padding: 10px 15px
.loading-file-process
  height: 12px
  border-radius: 5px
  margin: 5px 0
  background: #fff
  text-align: center
  font-size: 10px
  position: relative
  span.process-text
    display: block
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%,-50%)
    margin-top: -2px
    font-weight: 500
  span.process-line
    display: block
    height: 100%
    background: #0abb87
    border-radius: 10px

#storage-modal
  .modal-body
    padding: 0 !important
.storage__crumbs
  padding-top: 15px
  display: flex
  align-items: center
.storage__crumb-item
  padding-right: 30px
  position: relative
  &:not(:last-child):after
    display: block
    width: 4px
    height: 4px
    border-radius: 50%
    content: " "
    background: #5867dd
    position: absolute
    right: 15px
    top: 50%
    transform: translateY(-50%)
.storage__content
  display: flex
.storage__info
  width: 250px
  min-width: 250px
  min-height: 70vh
  background: #f7f7f7
  padding: 15px 25px
  display: flex
  flex-direction: column
  position: relative
  overflow-y: auto
.storage__course-list
  flex-grow: 1
  max-height: calc(100% - 80px)
  overflow-y: auto
  padding-right: 25px
  margin-right: -25px
  position: relative
  label.btn
    display: flex
    align-items: center
    justify-content: center
    position: sticky
    top: 0
    font-size: 15px
    font-weight: 500
.storage__course-button
  font-size: 12px
  width: 100%
  text-align: left
  margin-bottom: 5px
  &:hover:not(.chosen)
    background: #eee
  &.chosen
    background: #ccc
.storage__memory
  display: flex
  flex-direction: column
  justify-content: flex-end
  flex-grow: 1
  text-align: center
  position: absolute
  bottom: 15px
  left: 25px
  right: 25px
  padding-top: 15px
.storage__memory--indicator
  width: 100%
  height: 4px
  margin-bottom: 10px
  border-radius: 25px
  display: block
  background: #ccc
  position: relative
.storage__memory--process
  display: inline-block
  background: #5867dd
  position: absolute
  inset: 0
.storage__body
  display: flex
  flex-direction: column
  padding: 0 25px 15px 25px
  flex-grow: 1
.storage__top
  width: 100%
  padding: 15px 40px 15px 0
  position: relative
  h3
    font-size: 1.5rem
    margin: 0
.storage__close
  position: absolute !important
  right: 0
  top: 50%
  transform: translateY(-50%)
.storage__menu
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: space-between
  padding: 10px 0
  border-bottom: 1px solid #eee
  .btn-sm
    width: 2rem !important
    height: 2rem !important
.storage__files-wrapper
  flex-grow: 1
  padding-bottom: 15px
  position: relative
.storage__files
  padding-top: 15px
  display: flex
  align-items: flex-start
  flex-wrap: wrap
  max-height: 60vh
  overflow-y: auto
.storage__file-item
  padding: 0
  border: 2px solid #eeeeee
  border-radius: 7px
  height: auto
  margin-right: 10px
  cursor: pointer
  margin-bottom: 10px
  position: relative
  &:hover,
  &.chosen
    border-color: #5867dd
  img
    max-width: 100%
    max-height: 100%
    margin: 0 auto
.storage__item-preview
  display: flex
  align-items: center
  justify-content: center
  height: 100px
  width: 170px
  padding: 5px
  text-align: center
  background: #eee
  border-radius: 5px
  border-bottom-left-radius: 0
  border-bottom-right-radius: 0
  .no-image
    display: flex
    align-items: center
    justify-content: center
    font-size: 40px
    text-transform: uppercase
    height: 100%
    font-weight: 900
  .folder &
    background: transparent
    border-bottom: 1px solid #eee
    i
      font-size: 68px
.storage__item-foot
  padding: 5px
  p
    width: 150px
.storage__bottom
  display: flex
  justify-content: flex-end
  padding-top: 15px
  border-top: 1px solid #eee
.storage__file-size
  position: absolute
  bottom: 30px
  right: 0
  background: #000
  color: #fff
  padding: 2px 3px
  font-weight: 700
  font-size: 10px

@media screen and (max-width: 991px)
  .storage__content
    flex-direction: column
  .storage__course-list
    padding-right: 0
    width: 100%
  .storage__info
    min-height: 140px
    width: 100%
    padding: 15px
  .storage__body
    padding: 0
  .storage__top
    padding: 10px 40px 10px 15px
    h3
      font-size: 1.3rem
  .storage__close
    i
      font-size: 1rem
  .storage__crumbs
    padding: 15px 15px 0 15px
  .storage__files
    max-height: 50vh
    padding: 15px 7.5px
    //margin: 0 -7.5px
    overflow-x: hidden
  .storage__file-item
    margin: 5px 7.5px
    width: calc(50% - 15px)
  .storage__item-preview
    width: 100%
  .storage__item-foot
    p
      width: 100%
  .storage__bottom
    padding: 7.5px 15px
</style>