import axios from 'axios'
import store from '../../store';
import errorResponseHandler from '../responseError/errorResponseHandler'

export function topUpBalance(meta) {
    return new Promise((res, rej) => {
        axios.post(store.state.url + '/api/v1/users/top-up', meta, store.state.credentials)
            .then(function (response) {
                res(response.data)
            })
            .catch(function (error) {
                rej(errorResponseHandler(error.response))
            })
    })
}

export function getBalanceForAdmin() {
    return new Promise((res, rej) => {
        axios.get(store.state.url + '/api/v1/show-admin-balance', store.state.credentials)
            .then(function (response) {
                res({
                    data: response.data
                })
            })
            .catch(function (error) {
                rej(errorResponseHandler(error.response))
            });
    });
}