import axios from "axios";
import store from "../../store";
import errorResponseHandler from "../responseError/errorResponseHandler";

export function showProfile() {
  return new Promise((res, rej) => {
    axios
      .get(store.state.url + "/api/v1/showProfile", store.state.credentials)
      .then(function (response) {
        res({
          data: response.data,
        });
      })
      .catch(function (error) {
        rej(errorResponseHandler(error.response));
      });
  });
}

export function updateProfile(meta) {
  return new Promise((res, rej) => {
    axios
      .put(
        store.state.url + "/api/v1/updateProfile",
        meta,
        store.state.credentials
      )
      .then(function (response) {
        res(response.data);
      })
      .catch(function (error) {
        rej(errorResponseHandler(error.response));
      });
  });
}

export function placeImage(meta) {
  return new Promise((res, rej) => {
    axios
      .post(
        store.state.url + "/api/v1/save-file",
        meta,
        store.state.credentials
      )
      .then(function (response) {
        res(response.data);
      })
      .catch(function (error) {
        rej(errorResponseHandler(error.response));
      });
  });
}