<template>
  <div class="kt-datatable__pager kt-datatable--paging-loaded">
    <ul class="kt-datatable__pager-nav">
      <li v-on:click="changePage('first')">
        <a
          :title="$t('pagination.first')"
          class="kt-datatable__pager-link kt-datatable__pager-link--first"
          :class="{'kt-datatable__pager-link--disabled': pagination.current_page === 1}">
          <i class="flaticon2-fast-back"></i>
        </a>
      </li>
      <li v-on:click="changePage('prev')">
        <a
          :title="$t('pagination.prev')"
          class="kt-datatable__pager-link kt-datatable__pager-link--prev"
          :class="{'kt-datatable__pager-link--disabled': pagination.current_page === 1}">
          <i class="flaticon2-back"></i>
        </a>
      </li>
      <li v-if="pagination.current_page > 3" class="align-self-end">. . .</li>
      <template
        v-for="page in pagination.current_page + 4 < pagination.total_pages ? pagination.current_page + 4 : pagination.total_pages"
      >
        <li
          v-if="pagination.current_page - 3 < 0 && page > pagination.current_page - 3"
          v-on:click="changePage(page)"
          :key="page"
        >
          <a
            class="kt-datatable__pager-link kt-datatable__pager-link-number"
            :class="{'kt-datatable__pager-link--active': page == pagination.current_page}" :title="page"
          >
            {{page}}
          </a>
        </li>
        <li
          v-else-if="page > pagination.current_page - 3 && page < pagination.current_page + 3"
          v-on:click="changePage(page)"
          :key="page"
        >
          <a
            class="kt-datatable__pager-link kt-datatable__pager-link-number"
            :class="{'kt-datatable__pager-link--active': page == pagination.current_page}" :title="page"
          >
            {{page}}
          </a>
        </li>

      </template>
      <li v-if="pagination.total_pages > 5 && pagination.total_pages - pagination.current_page > 2" class="align-self-end">. . .</li>
      <li v-on:click="changePage('next')">
        <a
          :title="$t('pagination.next')"
          class="kt-datatable__pager-link kt-datatable__pager-link--next"
          :class="{'kt-datatable__pager-link--disabled': pagination.current_page === pagination.total_pages}">
          <i class="flaticon2-next"></i>
        </a>
      </li>
      <li v-on:click="changePage('last')">
        <a
          :title="$t('pagination.last')"
          class="kt-datatable__pager-link kt-datatable__pager-link--last"
          :class="{'kt-datatable__pager-link--disabled': pagination.current_page === pagination.total_pages}">
          <i class="flaticon2-fast-next"></i>
        </a>
      </li>
    </ul>
    <div class="kt-datatable__pager-info">
      <div class="dropdown bootstrap-select kt-datatable__pager-size" style="width: 60px;">
        <div class="dropdown-menu ">
          <div class="inner show" role="listbox" id="bs-select-3" tabindex="-1">
            <ul class="dropdown-menu inner show" role="presentation"></ul>
          </div>
        </div>
      </div>
      <div class="mb-0" style="margin-right: 10px" v-if="$route.path === '/orders'">
        <input class="form-check-input" id="hideDeleted" type="checkbox" v-model="hideAllDeleted">
        <label class="form-check-label kt-datatable__pager-detail ml-1" style="color: #646c9a;" for="hideDeleted">
          {{$t('order.hideDeleted')}}
        </label>
      </div>
      <div class="mb-1 mt-1" style="margin-right: 10px" v-if="$route.path === '/orders'">
        <ModelListSelect
            :list="perPageCount"
            v-model="per_page"
            option-value="id"
            option-text="name"
            @input="changePerPage($event)">
        </ModelListSelect>
      </div>
      <span class="kt-datatable__pager-detail">
        {{$t('pagination.showing')}}
        <template >
          {{pagination.per_page * (pagination.current_page - 1) + 1}}
        </template>
        -
        <template v-if="pagination.current_page < pagination.total_pages">
          {{pagination.per_page * pagination.current_page}}
        </template>
        <template v-else>
          {{pagination.total}}
        </template>
        <template v-if="user.role.role !== 'registrar'">
          {{$t('pagination.of')}}
          {{pagination.total}}
        </template>
        <template v-if="ticketsCount">
          ({{ticketsCount}})
        </template>
      </span>
    </div>
  </div>
</template>

<script>
  import {ModelListSelect} from 'vue-search-select'
  export default {
    name: 'Pagination',
    components: {
      ModelListSelect,
    },
    data () {
      return {
        hideAllDeleted: true,
        perPageCount: [
          { id: 1, name: 20 },
          { id: 2, name: 30 },
          { id: 3, name: 40 }
        ],
        per_page: { id: 1, name: 20 },
      }
    },
    props: ['pagination', 'hideDeleted', 'ticketsCount'],
    watch: {
      hideAllDeleted: function(value) {
        this.$emit('changeHideOrders', value)
      }
    },
    mounted() {
      this.hideAllDeleted = this.hideDeleted
      this.per_page = this.perPageCount.find(p => p.name == this.pagination.per_page)
    },
    computed: {
      user() {
        return this.$store.getters.user
      }
    },
    methods: {
      changePage(page) {
        if(page == 'first'){
          if(this.pagination.current_page == 1){
            return false;
          }
          this.pagination.current_page = 1;
        } else if(page == 'prev'){
          if(this.pagination.current_page <= 1){
            return false;
          }
          this.pagination.current_page--;
        } else if (page == 'next'){
          if(this.pagination.current_page >= this.pagination.total_pages){
            return false;
          }
          this.pagination.current_page++;
        } else if (page == 'last'){
          if(this.pagination.current_page == this.pagination.total_pages){
            return false;
          }
          this.pagination.current_page = this.pagination.total_pages;
        } else {
          this.pagination.current_page = page;
        }
        this.$emit('changePage', this.pagination.current_page);
      },
      changePerPage(item) {
        this.$emit('updatePerPage', item.name )
      }
    }
  }
</script >

<style lang="scss" >
.kt-datatable__pager .selection {
  caret-color: transparent;
  line-height: .5em!important;
}
.kt-datatable__pager .selection .dropdown.icon{
  line-height: .55em!important;
}
.kt-datatable.kt-datatable--default>.kt-datatable__pager>.kt-datatable__pager-info {
  flex-wrap: wrap;
}
</style>
