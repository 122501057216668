import axios from 'axios'
import store from '../../store';
import errorResponseHandler from '../responseError/errorResponseHandler'

export function addFileToStorage(meta) {
    const config = {
        onUploadProgress: function(progressEvent) {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            let meta = {
                loaded: progressEvent.loaded,
                total: progressEvent.total,
                percent: percentCompleted
            }
            store.dispatch('setUploadProgress', meta).then(() => {})
        },
        headers: store.state.credentials.headers
    }
    return new Promise((res, rej) => {
        axios.post(store.state.url + '/api/v1/attachments', meta, config)
            .then(function (response) {
                store.dispatch('setUploadProgress', {loaded: 0, total: 0, percent: 0}).then(() => {})
                res({
                    data: response.data
                })
            })
            .catch(function (error) {
                store.dispatch('setUploadProgress', {loaded: 0, total: 0, percent: 0}).then(() => {})
                rej(errorResponseHandler(error.response));
            });
    });
}

export function updateFile(attachmentId, meta) {
	return new Promise((res, rej) => {
		axios.put(store.state.url + '/api/v1/attachments/' + attachmentId, meta, store.state.credentials)
			.then(function (response) {
				res({
					data: response.data
				})
			})
			.catch(function (error) {
				rej(errorResponseHandler(error.response));
			});
	});
}

export function getUserFiles(params) {
    return new Promise((res, rej) => {
        axios.get(store.state.url + '/api/v1/attachments', {
					headers: store.state.credentials.headers,
					params: params
				})
				.then(function (response) {
						res({
								data: response.data
						})
				})
				.catch(function (error) {
						rej(errorResponseHandler(error.response));
				});
    });
}

export function createFolder(meta) {
	return new Promise((res, rej) => {
		axios.post(store.state.url + '/api/v1/attachment-folders', meta, store.state.credentials)
		.then(function (response) {
			res({
				data: response.data
			})
		})
		.catch(function (error) {
			rej(errorResponseHandler(error.response));
		});
	});
}

export function updateFolder(folderId, meta) {
	return new Promise((res, rej) => {
		axios.put(store.state.url + '/api/v1/attachment-folders/' + folderId, meta, store.state.credentials)
		.then(function (response) {
			res({
				data: response.data
			})
		})
		.catch(function (error) {
			rej(errorResponseHandler(error.response));
		});
	});
}

export function deleteFolder(folderId) {
	return new Promise((res, rej) => {
		axios.delete(store.state.url + '/api/v1/attachment-folders/' + folderId, store.state.credentials)
		.then(function (response) {
			res({
				data: response.data
			})
		})
		.catch(function (error) {
			rej(errorResponseHandler(error.response));
		});
	});
}

export function deleteFile(fileId) {
    return new Promise((res, rej) => {
        axios.delete(store.state.url + '/api/v1/attachments/' + fileId, store.state.credentials)
            .then(function (response) {
                res({
                    data: response.data
                })
            })
            .catch(function (error) {
                rej(errorResponseHandler(error.response));
            });
    });
}

