<template>
    <div class="kt-header__topbar-item kt-header__topbar-item--langs" :class="{'show': showLanguageModal}" v-click-outside="onClickOutsideLang">
        <div class="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="10px,0px" v-on:click="showLanguageModal = !showLanguageModal" >
            <span class="kt-header__topbar-icon" >
              <img style="margin-top:-2px;" :src="currLangImg" alt="" />
            </span>
        </div>
        <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround lang-bar-modal" :class="{'show': showLanguageModal}">
            <ul class="kt-nav kt-margin-t-10 kt-margin-b-10">
                <li class="kt-nav__item" v-for="item in languages" v-bind:key="item.id" v-on:click="setLocal(item)">
                    <a href="#" @click.prevent="()=>{return false}" class="kt-nav__link">
                        <span class="kt-nav__link-icon"><img :src="item.langImgSrc" alt="" /></span>
                        <span :class="['kt-nav__link-text', { 'active-link':  curLang === item.local}]" v-bind:style= "[
                            curLang === item.local ? { 'background': '#5d78ff', 'color': 'white', 'text-align': 'center', 'border-radius': '3px'} : {'background': 'white'} ]">
                            {{item.langName}}
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>


<script>
import vClickOutside from 'v-click-outside'
import {langList} from '../../locales/langList.js'

export default {
    name: 'LangBar',
    data () {
        return {
            languages: langList,
            showLanguageModal: false
        }
    },
    computed: {
        currLangImg () {
            if (this.$store.state.curLanguage.locale === 'ru_ru') {
              return '/assets/media/flags/glob.png'
            } else if (this.$store.state.curLanguage.locale === 'uk_ua') {
              return '/assets/media/flags/ukr.png'
            } else if (this.$store.state.curLanguage.locale === 'es_ES') {
              return '/assets/media/flags/128-spain.svg'
            } else {
              return '/assets/media/flags/226-united-states.svg'
            }
        },
        curLang () {
            return this.$store.state.curLanguage.locale;
        }
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    methods: {
        onClickOutsideLang () {
            this.showLanguageModal = false;
        },
        setLocal (lang) {
            this.showLanguageModal = false;
            let newLang = {
                lang: lang.local,
                img: lang.langImgSrc
            };
            this.$i18n.locale = lang.local;
            this.$store.dispatch('setLanguage', newLang);
        }
    }
}
</script>

<style lang="sass">
  .lang-bar-modal
      left: auto
      right: 105px
</style>