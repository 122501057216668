<template>
  <div class="kt-grid kt-grid--hor kt-grid--root">
    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
      <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app',
  beforeMount() {
    this.$i18n.locale = this.$store.state.curLanguage.locale
  },
  mounted() {
    if (this.$route.query.referrer_url) {
      this.$store.dispatch('setReferrer',this.$route.query.referrer_url)
      this.$router.replace({query: {}})
    } else {
      this.$store.dispatch('setReferrer', document.referrer)
    }
  }
}
</script>

<style lang="scss">

</style>