<template>
    <div class="kt-grid kt-grid--hor kt-grid--root">
      <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
        <AsideMenu />
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
          <TopNavBar @loading="setLoading" />
          <template v-if="!loading">
            <slot />
          </template>
          <div class="global-loading" v-else>
            <Loading />
          </div>
        </div>
        <div class="kt-aside-overlay" v-show="showMobileAside"/>
        <div class="modal-backdrop fade show" v-show="showBackDrop"/>
      </div>
    </div>
</template>

<script>
import AsideMenu from '../components/asideMenu/AsideMenu.vue'
import TopNavBar from '../components/topNavBar/TopNavBar.vue'

export default {
  name: 'app',
  components: {
    AsideMenu,
    TopNavBar
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    minimizeMenu() {
      return this.$store.state.isMinimizeMenu
    },
    isLogin(){
      return this.$store.state.user.isLogin
    },
    showMobileHeader() {
      return this.$store.state.showMobileHeader
    },
    showMobileAside() {
      return this.$store.state.showMobileMenu
    },
    showBackDrop() {
      return this.$store.state.showBackDrop
    },
    user() {
      return this.$store.state.user
    }
  },
  beforeMount() {
    this.$i18n.locale = this.$store.state.curLanguage.locale
  },
  methods: {
    setLoading(data) {
      this.loading = data
    }
  }
}
</script>

<style lang="scss">
.global-loading {
  position: fixed;
  inset: 0;
  z-index: 10000;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>