import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {i18n} from './locales/config'
import vClickOutside from 'v-click-outside'
import VueTippy from "vue-tippy";
import SubHeader from "./components/subHeader/SubHeader";
import Loading from "./components/loading/Loading";
import Pagination from "./components/pagination/Pagination";

import DefaultLayout from './layouts/Default.vue'
Vue.component('default-layout', DefaultLayout)

import AuthLayout from './layouts/Auth.vue'
Vue.component('auth-layout', AuthLayout)

import EmptyLayout from './layouts/Empty.vue'
Vue.component('empty-layout', EmptyLayout)

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import VueSweetalert2 from 'vue-sweetalert2'
Vue.use(VueSweetalert2);

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate);

import CKEditor from '@ckeditor/ckeditor5-vue'
Vue.use(CKEditor);

import VueKonva from 'vue-konva'
Vue.use(VueKonva)

import UUID from "vue-uuid";
Vue.use(UUID);

import vPikaday from 'vue-pikaday'
Vue.use(vPikaday)

import VueCryptojs from 'vue-cryptojs'
Vue.use(VueCryptojs)

import VueTour from 'vue-tour'
require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)

import Editor from 'v-markdown-editor'
import 'v-markdown-editor/dist/v-markdown-editor.css';
Vue.use(Editor);

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)
// import 'v-tooltip/dist/v-tooltip.css'

Vue.use(VueTippy, {
	directive: "tippy", // => v-tippy
	flipDuration: 0,
	popperOptions: {
		modifiers: {
			preventOverflow: {
				enabled: false
			}
		}
	}
});

import moment from 'moment';
Vue.prototype.$moment = moment;

import 'vue-search-select/dist/VueSearchSelect.css'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import './registerServiceWorker'

import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
Vue.use(VueSidebarMenu)

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

// import VueQRCodeComponent from 'vue-qrcode-component'
// Vue.component('qr-code', VueQRCodeComponent)

import VueQrcodeReader from 'vue-qrcode-reader'

Vue.use(VueQrcodeReader)

Vue.component('SubHeader', SubHeader)
Vue.component('Loading', Loading)
Vue.component('Pagination', Pagination)

Vue.filter('format', function (value, format = 'DD.MM.YYYY') {
  if (!value) return 'No date'
  return moment(value).format(format);
})

Vue.config.productionTip = false;

Object.defineProperty(Vue.prototype,"$bus",{
  get: function() {
    return this.$root.bus;
  }
});

new Vue({
  router,
  i18n,
  store,
  vClickOutside,
  data: {
    bus: new Vue({})
  },
  render: h => h(App)
}).$mount('#app');