export default function administrator ({ next, store, nextMiddleware}){
    // console.log('Хук регистратора');
    if(!(store.getters.user.role.role === 'registrar' ||
        store.getters.user.role.role === 'admin' ||
        store.getters.user.role.role === 'organizer' ||
        store.getters.user.role.role === 'co_organizer')){
      return next({
        name: 'noAccess'
      })
    }
    return nextMiddleware()
}