export default {
	state: {
		showStorage: false,
		storageTarget: '',
		storageSelected: null,
		storageUploadProgress: {
			percent: 0,
			total: 0,
			loaded: 0
		},
	},
	mutations: {
		toggleStorage(state, payload) {
			if (!state.showStorage) {
				payload ? state.storageTarget = payload : state.storageTarget = ''
			}
			
			state.showStorage = !state.showStorage
		},
		selectFile(state, payload) {
			state.storageSelected = {
				target: state.storageTarget,
				data: payload
			}
			state.showStorage = false
		},
		setUploadProgress(state, payload) {
			state.storageUploadProgress = payload
		},
	},
	actions: {
		toggleStorage(context, response) {
			context.commit('toggleStorage', response)
		},
		selectFile(context, response) {
			context.commit('selectFile', response)
		},
		setUploadProgress(context, response) {
			context.commit('setUploadProgress', response)
		},
	},
	getters: {
		showStorage(state) {
			return state.showStorage
		},
		storageTarget(state) {
			return state.storageTarget
		},
		storageSelected(state) {
			return state.storageSelected
		},
		uploadProgress(state) {
			return state.storageUploadProgress
		}
	}
}