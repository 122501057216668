<template>
  <div
      id="app"
      class="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right "
      :class="{
        'kt-aside--minimize': minimizeMenu, 'kt-aside--on': showMobileAside,
        'kt-header__topbar--mobile-on': showMobileHeader,
        'kt-header--fixed kt-header-mobile--fixed kt-subheader--enabled kt-subheader--fixed kt-subheader--solid kt-aside--enabled kt-aside--fixed': layout === 'default-layout',
        'kt-header-full-width': user.role.role === 'registrar'
      }"
  >
    <component :is="$route.meta.layout || 'default-layout'">
      <router-view />
    </component>
    <div class="modal-backdrop fade show" v-show="showBackDrop"/>
  
    <template v-if="user.role.role === 'admin'">
      <StorageModal v-if="showStorage" />
    </template>
    <template v-else>
      <imageUploader v-if="showStorage" />
    </template>
  </div>
</template>

<script>
import StorageModal from '@/components/storage/StorageModal.vue'
import AsideMenu from '@/components/asideMenu/AsideMenu.vue'
import TopNavBar from './components/topNavBar/TopNavBar.vue'
import imageUploader from "@/components/ImageUploader.vue";
import axios from 'axios'

export default {
  name: 'app',
  components: {
    AsideMenu,
    TopNavBar,
    imageUploader,
    StorageModal
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'default-layout'
    },
    minimizeMenu() {
      return this.$store.state.isMinimizeMenu
    },
    isLogin(){
      return this.$store.state.user.isLogin
    },
    showMobileHeader() {
      return this.$store.state.showMobileHeader
    },
    showMobileAside() {
      return this.$store.state.showMobileMenu
    },
    showBackDrop() {
      return this.$store.state.showBackDrop
    },
    user() {
      return this.$store.state.user
    },
    showStorage() {
      return this.$store.getters.showStorage
    },
    loading() {
      return this.$store.getters.loading
    }
  },
  beforeMount() {
    if (localStorage.getItem('countryDetected') !== '1') {
      axios.get('https://ipapi.co/json/').then((response) => {
        let data = response.data;
        switch (data.country_code.toLowerCase()) {
          case 'ua':
            this.$store.dispatch('setLanguage', {lang: 'uk_ua', img: '/assets/media/flags/ukr.png'});
            localStorage.setItem('countryDetected', '1')
            break
          case 'ru':
            this.$store.dispatch('setLanguage',{lang: 'ru_ru', img: '/assets/media/flags/glob.png'})
            localStorage.setItem('countryDetected', '1')
            break
          default:
            this.$store.dispatch('setLanguage',{lang: 'en_us', img: '/assets/media/flags/226-united-states.svg'})
            localStorage.setItem('countryDetected', '1')
            break
        }
        this.$i18n.locale = this.$store.state.curLanguage.locale
      })
    }
    this.$i18n.locale = this.$store.state.curLanguage.locale
  },
}
</script>

<style lang="sass">
.kt-header-full-width
  #kt_wrapper
    padding-left: 0 !important
#app
  min-height: 100vh
.kt-grid
  height: auto
.cursor-pointer
  cursor: pointer
.modal-backdrop
  opacity: .3 !important
.multiselect
  font-size: 1rem
  .multiselect__tag,
  .multiselect__input,
  .multiselect__input::placeholder,
  .multiselect__single,
  .multiselect__option
    font-size: 1rem
</style>