export const langList = [
    {
        langName: 'Русский',
        langImgSrc: '/assets/media/flags/glob.png',
        local: 'ru_ru'
    },
    {
        langName: 'English',
        langImgSrc: '/assets/media/flags/226-united-states.svg',
        local: 'en_us'
    },
    {
        langName: 'Українська',
        langImgSrc: '/assets/media/flags/ukr.png',
        local: 'uk_ua'
    },
    {
        langName: 'Español',
        langImgSrc: '/assets/media/flags/128-spain.svg',
        local: 'es_ES'
    },
]