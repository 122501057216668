export default function administrator ({ next, store, nextMiddleware}){
  // console.log('Хук партнера');
  if(store.getters.user.role.role === 'sales_manager' ||
      store.getters.user.role.role === 'ticket_partner' ||
      store.getters.user.role.role === 'admin' ||
      store.getters.user.role.role === 'organizer' ||
      store.getters.user.role.role === 'co_organizer'){
      return nextMiddleware()
  }
  return next({
      name: 'noAccess'
  })

}